import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'
import { commonStyles } from '../../_common_styles'

export const SectionWrapper = styled(commonStyles.sectionWrapper)`
`

export const AppAndCardImage = styled.picture`
  display: flex;
  margin-bottom: 24px;
  align-items: center;

  @media ${device.tablet} {
    margin: 0;
  }
`

export const AppAndCardImageSource = styled.img`
  width: 312px;
  height: 390px;
  
  margin: 0 auto;

  @media ${device.tablet} {
    width: 100%;
    margin: 0;

    max-height: max-content;
    height: fit-content;
  }
`

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto 0;
`

export const Title = styled(commonStyles.sectionSecondaryTitle)`
`

export const Description = styled.p`
  font-family: "Inter", Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: white;

  margin: 24px 0;

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
  }

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 22px;

    margin: 40px 0;
  }
`

export const BenefitsList = styled.ul`
  margin: 0;
`

export const Benefit = styled.li`
  list-style: none;

  .benefits_list {
    &__item {
      margin: 16px 0;

      &__icon {
        &__source {
          width: 24px;
          height: 24px;

          @media ${device.tablet} {
            width: 28px;
            height: 28px;
          }

          @media ${device.desktopLG} {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`
